/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { NotificationSubmissionPayload } from 'app/models/notification.model';
import { FeedbackItem } from '@opengamma/ui';

@Injectable()
export class NotificationSubmissionService {
  constructor(private httpClient: HttpClient) {}
  submitDecision(payload: NotificationSubmissionPayload): Observable<FeedbackItem> {
    return this.httpClient.post<FeedbackItem>(
      [environment.baseUrl, 'margin-call'].join('/'),
      payload
    );
  }

  isSubmitted(token: string): Observable<boolean> {
    return this.httpClient.get<boolean>([environment.baseUrl, 'is-submitted'].join('/'), {
      params: { token }
    });
  }
}
