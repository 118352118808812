/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CallDirection,
  CallRole,
  CounterpartyMarginCallDecision,
  NotificationData,
  NotificationsDecisionButton,
  NotificationSubmissionPayload
} from 'app/models/notification.model';
import { NotificationSubmissionService } from 'app/services/notification-submission.service';
import { catchError, take } from 'rxjs/operators';
import _ from 'lodash';
import {
  CALL_DECISION_MAPPED_TO_REQUIRED_FIELDS,
  CALL_RADIO_BUTTONS,
  COUNTERPARTY_AMOUNT_ENTRY_FIELD,
  EMAIL_ENTRY_FIELD,
  NOTES_ENTRY_FIELD,
  ZERO_CURRENCY_VALUE
} from 'app/components/notification-inputs/notification-form-fields';
import {
  Color,
  CurrencyValue,
  CurrencyValueEntryField,
  EntryFieldDataType,
  EntryStatusInfo,
  IconStroke,
  LoadableState,
  observableOf,
  RadioButtonGroup,
  TextEntryField
} from '@opengamma/ui';
import { BehaviorSubject } from 'rxjs';
import getSymbolFromCurrency from 'currency-symbol-map';

@Component({
  selector: 'og-t-notification-inputs',
  templateUrl: './notification-inputs.component.html',
  styleUrls: ['./notification-inputs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationInputsComponent {
  notificationData: NotificationData;
  submissionPayload: NotificationSubmissionPayload = {
    notificationDecision: 'APPROVE',
    email: '',
    notes: '',
    notificationId: '',
    counterpartyPerspectiveCallRole: 'PLEDGOR',
    counterpartyPerspectiveCallDirection: 'INCOMING',
    counterpartySuggestedAmount: ZERO_CURRENCY_VALUE
  };

  decisionButtons: NotificationsDecisionButton[];
  defaultDecisionButtons: NotificationsDecisionButton[] = [
    {
      value: 'APPROVE',
      icon: 'check',
      text: 'Accept Call Amount',
      tooltip: 'Fully agree with the amount'
    },
    {
      value: 'EDIT',
      icon: 'x',
      text: 'Propose New Amount',
      tooltip: 'Propose a new call amount on the basis that the above call details are incorrect'
    },
    {
      value: 'REJECT',
      icon: 'x',
      text: 'Reject',
      tooltip: 'Reject the above call amount'
    }
  ];

  notificationDecisionToDisplay: string;

  submissionLoadableState$: BehaviorSubject<
    LoadableState<string> | undefined
  > = new BehaviorSubject(undefined);

  callRadioButtons: [
    RadioButtonGroup<CallDirection>,
    RadioButtonGroup<CallRole>
  ] = CALL_RADIO_BUTTONS;
  emailEntryField: TextEntryField = EMAIL_ENTRY_FIELD;
  notesEntryField: TextEntryField = NOTES_ENTRY_FIELD;
  counterpartyAmountField: CurrencyValueEntryField = COUNTERPARTY_AMOUNT_ENTRY_FIELD;

  fieldIdsMappedToEntryStatus: { [fieldId: string]: EntryStatusInfo };

  IconStroke = IconStroke;

  constructor(private callSubmissionService: NotificationSubmissionService) {}

  @Input() set defaultNotificationData(notificationData: NotificationData) {
    this.notificationData = notificationData;
    this.decisionButtons =
      notificationData.decisionDetails?.availableButtons || this.defaultDecisionButtons;

    this.onCallDecisionChange(notificationData.decisionDetails?.notificationDecision);
    const initializedPayload = {
      ...this.submissionPayload,
      notificationDecision: notificationData.decisionDetails?.notificationDecision,
      notificationId: notificationData.notificationId
    };

    // only override if a suggested value exists
    if (notificationData.decisionDetails?.suggestedCounterpartyCallRole) {
      initializedPayload.counterpartyPerspectiveCallRole =
        notificationData.decisionDetails?.suggestedCounterpartyCallRole;
    }
    if (notificationData.decisionDetails?.suggestedCounterpartyCallDirection) {
      initializedPayload.counterpartyPerspectiveCallDirection =
        notificationData.decisionDetails?.suggestedCounterpartyCallDirection;
    }
    if (notificationData.decisionDetails?.suggestedCallAmount) {
      initializedPayload.counterpartySuggestedAmount = {
        price: notificationData.decisionDetails?.suggestedCallAmount.price,
        symbol:
          notificationData.decisionDetails?.suggestedCallAmount.symbol ??
          getSymbolFromCurrency(notificationData.decisionDetails?.suggestedCallAmount.isoCode),
        isoCode: notificationData.decisionDetails?.suggestedCallAmount.isoCode
      };
    }

    this.submissionPayload = initializedPayload;
  }

  onCallDecisionChange(value: CounterpartyMarginCallDecision): void {
    this.submissionPayload = {
      ...this.submissionPayload,
      notificationDecision: value
    };

    if (value === 'APPROVE') {
      this.notificationDecisionToDisplay = 'Approval';
      this.emailEntryField = {
        ...this.emailEntryField,
        label: 'Inputter email address'
      };
    } else if (value === 'EDIT') {
      this.notificationDecisionToDisplay = 'Proposal';
      this.emailEntryField = {
        ...this.emailEntryField,
        label: 'Inputter email address'
      };
    } else {
      this.notificationDecisionToDisplay = 'Rejection';
      this.emailEntryField = {
        ...this.emailEntryField,
        label: 'Inputter email address'
      };
    }
  }

  onSubmitDecision(): void {
    this.submissionLoadableState$.next({ loading: true });
    this.callSubmissionService
      .submitDecision(this.sanitizeSubmission())
      .pipe(
        take(1),
        catchError(e => {
          this.submissionLoadableState$.next({ loading: false, error: e.message });
          return observableOf(undefined);
        })
      )
      .subscribe(result => {
        if (result !== undefined) {
          if (result.feedbackType === 'OK') {
            this.submissionLoadableState$.next({ loading: false, data: result.description });
          } else {
            this.submissionLoadableState$.next({ loading: false, error: result.description });
          }
        }
      });
  }

  getNotesEntryField(): TextEntryField {
    const isApproval = this.submissionPayload.notificationDecision === 'APPROVE';
    return {
      ...this.notesEntryField,
      options: {
        ...this.notesEntryField.options,
        minimumNumberOfChars: !isApproval ? 1 : undefined,
        placeholder: isApproval
          ? 'e.g. Our records match the details above.'
          : 'e.g. Our records do not match the details above.'
      }
    };
  }

  isSubmissionDisabled(): boolean {
    const requiredFields =
      CALL_DECISION_MAPPED_TO_REQUIRED_FIELDS[this.submissionPayload.notificationDecision] ?? [];

    if (this.fieldIdsMappedToEntryStatus !== undefined && requiredFields.length > 0) {
      return Object.keys(this.fieldIdsMappedToEntryStatus)
        .filter(fieldId => requiredFields.includes(fieldId))
        .some(fieldId => this.fieldIdsMappedToEntryStatus[fieldId].status === 'invalid');
    }
    return true;
  }

  getSubmissionButtonTooltip(): string {
    let tooltipText = 'Enter a valid email address.';
    if (this.submissionPayload.notificationDecision !== 'APPROVE') {
      tooltipText += ' Enter a dispute reason.';
    }

    return tooltipText;
  }

  getSubmissionButtonSuccessText(submissionResponse: string): string {
    return (
      this.decisionButtons.find(
        decision => decision.value === this.submissionPayload.notificationDecision
      )?.buttonResponseText || submissionResponse
    );
  }

  getColor(): Color {
    switch (this.submissionPayload.notificationDecision) {
      case 'APPROVE':
        return 'info';
      case 'REJECT':
        return 'danger';
      case 'EDIT':
        return 'dark';
    }
  }

  onCounterpartyAmountChange(amount: CurrencyValue): void {
    this.onValueChange(amount, this.counterpartyAmountField.id);
  }

  onValueChange(value: EntryFieldDataType, fieldId: string): void {
    this.submissionPayload = {
      ...this.submissionPayload,
      [fieldId]: value
    };
  }

  onEntryStatusChange(status: EntryStatusInfo, fieldId: string): void {
    this.fieldIdsMappedToEntryStatus = {
      ...this.fieldIdsMappedToEntryStatus,
      [fieldId]: status
    };
  }

  private sanitizeSubmission(): NotificationSubmissionPayload {
    const requiredFields =
      CALL_DECISION_MAPPED_TO_REQUIRED_FIELDS[this.submissionPayload.notificationDecision];
    return _.pick(this.submissionPayload, [
      'notificationId',
      'notificationDecision',
      ...requiredFields
    ]) as NotificationSubmissionPayload;
  }
}
