<!--
~ Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
~
~ Please see distribution for license.
-->

<div fxLayout="column" fxLayoutGap="42px">
  <div
    class="color--dark text--lg text--bold"
    fxLayoutGap="8px"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <og-icon name="pencil" class="color--{{ getColor() }} fwef"></og-icon>
    @if (notificationData?.decisionDetails?.title) {
    <div [innerHTML]="notificationData.decisionDetails.title | safeHtml"></div>
    } @if (!notificationData?.decisionDetails?.title) {
    <div>Decision details</div>
    }
  </div>
  <div fxLayout="column" fxLayoutGap="16px">
    <div class="label color--dark text--bold text--md" fxLayout="row" fxLayoutGap="4px">
      <div>1.</div>
      @if (notificationData?.decisionDetails?.decisionSelectionLabel) {
      <div [innerHTML]="notificationData.decisionDetails.decisionSelectionLabel | safeHtml"></div>
      } @if (!notificationData?.decisionDetails?.decisionSelectionLabel) {
      <div>
        Select Call Decision
      </div>
      }
    </div>
    <div class="horizontal-divider"></div>
    <mat-button-toggle-group
      (change)="onCallDecisionChange($event.value)"
      class="call-decision__button-group"
      [class.call-decision__button-group--approval]="
        submissionPayload.notificationDecision === 'APPROVE'
      "
      [class.call-decision__button-group--not-expected]="
        submissionPayload.notificationDecision === 'REJECT'
      "
      [value]="submissionPayload.notificationDecision"
      name="fontStyle"
      aria-label="Font Style"
      hideSingleSelectionIndicator
    >
      @for (decisionButton of decisionButtons; track decisionButton) {
      <mat-button-toggle
        [disableRipple]="true"
        [value]="decisionButton.value"
        [matTooltip]="decisionButton.tooltip"
      >
        <div fxLayout="row" fxLayoutGap="12px" class="call-decision__button-contents">
          <og-icon [name]="decisionButton.icon" [stroke]="IconStroke.XThick"></og-icon>
          <div [innerHTML]="decisionButton.text | safeHtml"></div>
        </div>
      </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>

  <div fxLayout="column" fxLayoutGap="16px">
    <div class="label color--dark text--bold text--md">
      2. Enter {{ notificationDecisionToDisplay }} Details
    </div>
    <div class="horizontal-divider"></div>

    <div fxLayout="row" fxLayoutGap="24px" class="">
      <div fxLayout="column" fxLayoutGap="24px" fxFlex="300px">
        <og-entry-field
          theme="light"
          labelFlexDirection="column"
          [id]="emailEntryField.id"
          [field]="emailEntryField"
          [displayLabel]="true"
          [initialValueOverride]="emailEntryField.options.initialValue"
          (entryStatusChange)="onEntryStatusChange($event, emailEntryField.id)"
          (valueChange)="onValueChange($event, emailEntryField.id)"
        ></og-entry-field>
        @if (submissionPayload.notificationDecision === 'EDIT') {
        <og-entry-field
          theme="light"
          labelFlexDirection="column"
          [id]="counterpartyAmountField.id"
          [field]="counterpartyAmountField"
          [initialValueOverride]="submissionPayload.counterpartySuggestedAmount"
          [displayLabel]="true"
          (entryStatusChange)="onEntryStatusChange($event, counterpartyAmountField.id)"
          (valueChange)="onCounterpartyAmountChange($event)"
        ></og-entry-field>
        <div fxLayout="row" fxLayoutGap="32px">
          @for (buttonGroup of callRadioButtons; track buttonGroup) {
          <og-radio-button-group
            [buttonGroup]="buttonGroup"
            [selectedButtonId]="submissionPayload[buttonGroup.id]"
            (onButtonSelect)="onValueChange($event, buttonGroup.id)"
          ></og-radio-button-group>
          }
        </div>
        }
      </div>
      <div class="details-container" fxLayout="column" fxLayoutGap="12px" fxFlex="1 1 auto">
        <div class="color--gray text--bold text--xs">Notes</div>
        <og-entry-field
          theme="light"
          labelFlexDirection="column"
          [id]="notesEntryField.id"
          [field]="getNotesEntryField()"
          [displayLabel]="false"
          [initialValueOverride]="submissionPayload.notes"
          (entryStatusChange)="onEntryStatusChange($event, notesEntryField.id)"
          (valueChange)="onValueChange($event, notesEntryField.id)"
        ></og-entry-field>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="16px">
    <div class="label color--dark text--bold text--md">3. Submit your decision</div>
    <div class="horizontal-divider"></div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
      <og-button
        class="notifications__submit-button "
        [theme]="'secondary'"
        [matTooltip]="isSubmissionDisabled() ? getSubmissionButtonTooltip() : ''"
        (buttonClick)="onSubmitDecision()"
        [disabled]="(submissionLoadableState$ | async)?.data || isSubmissionDisabled()"
      >
        <div fxLayout="row" fxLayoutGap="24px">
          @if ((submissionLoadableState$ | async)?.loading) {
          <og-spinner size="tiny" theme="accent"></og-spinner>
          } @else {
          <og-icon name="send" class="color--accent"></og-icon>
          }
          <ng-template #icon>
            <og-icon name="send" class="color--accent"></og-icon>
          </ng-template>
          <div>Submit decision</div>
        </div>
      </og-button>
      @if (submissionLoadableState$ | async; as submissionLoadableState) { @if
      (submissionLoadableState.data) {
      <div fxLayout="row" fxLayoutGap="12px" class="call-decision__button-contents">
        <og-icon name="check" class="color--success"></og-icon>
        <div class="color--gray text--strong">
          {{ getSubmissionButtonSuccessText(submissionLoadableState.data) }}
        </div>
      </div>
      } @if (submissionLoadableState.error) {
      <div fxLayout="row" fxLayoutGap="12px" class="call-decision__button-contents">
        <og-icon name="alert-triangle-filled" class="color--warning"></og-icon>
        <div class="color--gray text--strong">{{ submissionLoadableState.error }}</div>
      </div>
      } }
    </div>
  </div>
</div>
