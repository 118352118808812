/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Environment } from './environment.model';

// Default dev environment, using the dev auth service and dev API.
export const environment: Environment = {
  production: true,
  baseUrl: 'notifications-api'
};
