<!--
~ Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
~
~ Please see distribution for license.
-->
<og-t-header></og-t-header>
<div class="sheet">
  @if (notificationData) {
  <div fxLayoutGap="32px" fxLayout="column">
    <div fxLayoutGap="8px" fxLayout="column">
      @if (notificationData?.header?.date) {
      <div class="date text--md text--strong color--gray">
        {{ notificationData?.header?.date }}
      </div>
      }
      <div class="title">
        {{ notificationData?.header?.title }} by
        <span class="color--accent">{{ notificationData?.header?.senderOrg }}</span>
      </div>
      <div class="subtitle text--md text--strong color--gray">
        @if (notificationData?.header?.subtitle) {
        <div [innerHTML]="notificationData?.header?.subtitle | safeHtml"></div>
        } @if ( notificationData?.header?.senderEmail || notificationData?.header?.recipientEmail )
        { sent @if (notificationData?.header?.recipientEmail) { to
        <span class="color--dark text--bold">
          {{ notificationData?.header?.recipientEmail }}
        </span>
        } by
        <span class="color--dark text--bold">{{ notificationData?.header?.senderEmail }}</span>
        via the OpenGamma Treasury Platform. }
      </div>
    </div>
    <div class="horizontal-divider"></div>
    <div class="color--dark text--lg text--bold">
      @if (notificationData?.callDetails?.title) {
      <div [innerHTML]="notificationData.callDetails.title | safeHtml"></div>
      } @if (!notificationData?.callDetails?.title) {
      <div>Call details</div>
      }
    </div>
    @for (section of notificationData.callDetails?.sections; track section) {
    <div fxLayoutGap="16px" fxLayout="column">
      @if (section?.pretext) {
      <div [innerHTML]="section.pretext | safeHtml"></div>
      } @if (section.grid) {
      <og-t-grid [notificationData]="section.grid"></og-t-grid>
      } @if (section?.subtext) {
      <div [innerHTML]="section.subtext | safeHtml"></div>
      }
    </div>
    }
    <div class="horizontal-divider"></div>
    @if (!(isSubmitted$ | async)) {
    <og-t-notification-inputs
      [defaultNotificationData]="notificationData"
    ></og-t-notification-inputs>
    } @else {
    <og-placeholder-icon theme="accent" imageName="checkmark">
      <div class="color--dark">
        {{
          notificationData?.errorMessages?.alreadySubmitted ||
            'Someone has already responded to this notification'
        }}
      </div>
    </og-placeholder-icon>
    }
  </div>
  } @else {
  <og-placeholder-icon theme="accent" imageName="box">
    <div class="color--dark">
      {{
        notificationData?.errorMessages?.missingData ||
          'The data for this page has gone missing. Either the link is broken or it expired.'
      }}
      <br />
      <br />
      Please reach out to
      <a
        class="color--accent"
        [attr.href]="
          'mailto:' + (notificationData?.errorMessages?.reachOutAddress || 'support@opengamma.com')
        "
      >
        {{ notificationData?.errorMessages?.reachOutAddress || 'support@opengamma.com' }}
      </a>
      .
    </div>
  </og-placeholder-icon>
  }
</div>

<ng-template #alreadySubmitted>
  <og-placeholder-icon theme="accent" imageName="checkmark">
    <div class="color--dark">
      {{
        notificationData?.errorMessages?.alreadySubmitted ||
          'Someone has already responded to this notification'
      }}
    </div>
  </og-placeholder-icon>
</ng-template>
