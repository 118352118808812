/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  CurrencyValue,
  CurrencyValueEntryField,
  RadioButtonGroup,
  TextEntryField
} from '@opengamma/ui';
import { CallDirection, CallRole } from 'app/models/notification.model';

export const ZERO_CURRENCY_VALUE: CurrencyValue = {
  isoCode: 'USD',
  price: 0,
  symbol: '$'
};

const COUNTERPARTY_PERSPECTIVE_CALL_DIRECTION: RadioButtonGroup<CallDirection> = {
  id: 'counterpartyPerspectiveCallDirection',
  label: 'Call Direction',
  direction: 'vertical',
  context: 'from your perspective',
  isDisabled: true,
  buttons: [
    {
      name: 'Incoming',
      value: 'INCOMING'
    },
    {
      name: 'Outgoing',
      value: 'OUTGOING'
    }
  ]
};

const COUNTERPARTY_PERSPECTIVE_CALL_ROLE: RadioButtonGroup<CallRole> = {
  id: 'counterpartyPerspectiveCallRole',
  label: 'Call Role',
  isDisabled: true,
  direction: 'vertical',
  context: 'from your perspective',
  buttons: [
    {
      name: 'Pledgor',
      value: 'PLEDGOR'
    },
    {
      name: 'Secured',
      value: 'SECURED'
    }
  ]
};

export const CALL_RADIO_BUTTONS: [RadioButtonGroup<CallDirection>, RadioButtonGroup<CallRole>] = [
  COUNTERPARTY_PERSPECTIVE_CALL_DIRECTION,
  COUNTERPARTY_PERSPECTIVE_CALL_ROLE
];

export const EMAIL_ENTRY_FIELD: TextEntryField = {
  type: 'text',
  id: 'email',
  dependencies: [],
  label: 'Disputer email address',
  options: {
    placeholder: 'e.g. your.email@example.com',
    type: 'email',
    minimumNumberOfChars: 0
  }
};

export const NOTES_ENTRY_FIELD: TextEntryField = {
  type: 'text',
  id: 'notes',
  dependencies: [],
  label: 'Notes',
  options: {
    displayTextArea: true,
    minimumNumberOfChars: 0
  }
};

export const COUNTERPARTY_AMOUNT_ENTRY_FIELD: CurrencyValueEntryField = {
  type: 'currency_value',
  id: 'counterpartySuggestedAmount',
  dependencies: [],
  label: 'Proposed new call amount',
  options: {
    initialValue: ZERO_CURRENCY_VALUE,
    placeholder: 'e.g. $1234.56',
    minimumValue: 1
  }
};

const commonRequiredFields = [NOTES_ENTRY_FIELD.id, EMAIL_ENTRY_FIELD.id];

export const CALL_DECISION_MAPPED_TO_REQUIRED_FIELDS: {
  APPROVE: string[];
  EDIT: string[];
  REJECT: string[];
} = {
  APPROVE: commonRequiredFields,
  EDIT: [
    ...commonRequiredFields,
    COUNTERPARTY_AMOUNT_ENTRY_FIELD.id,
    COUNTERPARTY_PERSPECTIVE_CALL_ROLE.id,
    COUNTERPARTY_PERSPECTIVE_CALL_DIRECTION.id
  ],
  REJECT: commonRequiredFields
};
