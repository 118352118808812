/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, OnInit } from '@angular/core';
import { NotificationsUrlDataProviderService } from 'app/services/url-data-provider.service';
import { NotificationData } from 'app/models/notification.model';
import { Observable } from 'rxjs';
import { NotificationSubmissionService } from 'app/services/notification-submission.service';

@Component({
  selector: 'og-t-notifications',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  notificationData: NotificationData;
  isSubmitted$: Observable<boolean>;
  constructor(
    dataService: NotificationsUrlDataProviderService,
    private callSubmissionService: NotificationSubmissionService
  ) {
    this.notificationData = dataService.getNotificationDataFromUrl();
  }

  ngOnInit() {
    this.isSubmitted$ = this.callSubmissionService.isSubmitted(
      this.notificationData?.notificationId
    );
  }
}
