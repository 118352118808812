<!--
  ~ Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="header__container">
  <div class="header" fxLayoutGap="8px" fxLayout="column" fxLayoutAlign="center">
    <img class="logo__img" src="assets/logos/solid-blue-open.png" />
    <div class="text--md text--strong color--gray">Treasury</div>
  </div>
  <div class="brand-line"></div>
</div>
