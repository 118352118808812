/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { NotificationCurrencyColumn, NotificationsGrid } from 'app/models/notification.model';
import { TableData } from '@opengamma/ui';
import { v4 as generateId } from 'uuid';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'og-t-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent {
  tableData: TableData;
  gridOptions: GridOptions = {
    rowModelType: 'clientSide',
    treeData: false,
    floatingFiltersHeight: 0,
    headerHeight: 50,
    suppressAutoSize: true
  };

  @Input() set notificationData(data: NotificationsGrid) {
    this.tableData = this.mapNotificationDataToTableData(data);
  }

  private mapNotificationDataToTableData(tableData: NotificationsGrid): TableData {
    const columnGroups = [
      { columns: tableData.columns.map(col => ({ ...col, id: col.label, isVisible: true })) }
    ];
    const rows = tableData.rows.map(row => {
      const gridRow = {};
      tableData.columns.forEach((col, i) => {
        if (row[i] === '' || row[i] === undefined) {
          return;
        }
        if (col.type === 'currency_value') {
          const isoCode = (col as NotificationCurrencyColumn).isoCode ?? tableData.isoCode;
          gridRow[col.label] = { price: row[i], isoCode };
        } else {
          gridRow[col.label] = row[i];
        }
      });
      return { values: gridRow, id: generateId() };
    });

    return {
      columnGroups,
      rows
    };
  }
}
