<!--
  ~ Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<og-t-header></og-t-header>
<div class="sheet">
  <ng-container *ngIf="notificationData; else malformedData">
    <div fxLayoutGap="32px" fxLayout="column">
      <div fxLayoutGap="8px" fxLayout="column">
        <div *ngIf="notificationData?.header?.date" class="date text--md text--strong color--gray">
          {{ notificationData?.header?.date }}
        </div>
        <div class="title">
          {{ notificationData?.header?.title }} by
          <span class="color--accent">{{ notificationData?.header?.senderOrg }}</span>
        </div>
        <div class="subtitle text--md text--strong color--gray">
          <div
            *ngIf="notificationData?.header?.subtitle"
            [innerHTML]="notificationData?.header?.subtitle | safeHtml"
          ></div>
          <ng-container
            *ngIf="
              notificationData?.header?.senderEmail || notificationData?.header?.recipientEmail
            "
          >
            sent
            <ng-container *ngIf="notificationData?.header?.recipientEmail">
              to
              <span class="color--dark text--bold">
                {{ notificationData?.header?.recipientEmail }}
              </span>
            </ng-container>
            by
            <span class="color--dark text--bold">{{ notificationData?.header?.senderEmail }}</span>
            via the OpenGamma Treasury Platform.
          </ng-container>
        </div>
      </div>
      <div class="horizontal-divider"></div>

      <div class="color--dark text--lg text--bold">
        <div
          *ngIf="notificationData?.callDetails?.title"
          [innerHTML]="notificationData.callDetails.title | safeHtml"
        ></div>
        <div *ngIf="!notificationData?.callDetails?.title">Call details</div>
      </div>
      <div
        fxLayoutGap="16px"
        fxLayout="column"
        *ngFor="let section of notificationData.callDetails?.sections"
      >
        <div *ngIf="section?.pretext" [innerHTML]="section.pretext | safeHtml"></div>
        <og-t-grid *ngIf="section.grid" [notificationData]="section.grid"></og-t-grid>
        <div *ngIf="section?.subtext" [innerHTML]="section.subtext | safeHtml"></div>
      </div>
      <div class="horizontal-divider"></div>
      <og-t-notification-inputs
        *ngIf="!(isSubmitted$ | async); else alreadySubmitted"
        [defaultNotificationData]="notificationData"
      ></og-t-notification-inputs>
    </div>
  </ng-container>
</div>
<ng-template #malformedData>
  <og-placeholder-icon theme="accent" imageName="box">
    <div class="color--dark">
      {{
        notificationData?.errorMessages?.missingData ||
          'The data for this page has gone missing. Either the link is broken or it expired.'
      }}

      <br />
      <br />
      Please reach out to
      <a
        class="color--accent"
        [attr.href]="
          'mailto:' + (notificationData?.errorMessages?.reachOutAddress || 'support@opengamma.com')
        "
      >
        {{ notificationData?.errorMessages?.reachOutAddress || 'support@opengamma.com' }}
      </a>
      .
    </div>
  </og-placeholder-icon>
</ng-template>

<ng-template #alreadySubmitted>
  <og-placeholder-icon theme="accent" imageName="checkmark">
    <div class="color--dark">
      {{
        notificationData?.errorMessages?.alreadySubmitted ||
          'Someone has already responded to this notification'
      }}
    </div>
  </og-placeholder-icon>
</ng-template>
