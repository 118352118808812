/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { NotificationData } from 'app/models/notification.model';
import * as pako from 'pako';

@Injectable()
export class NotificationsUrlDataProviderService {
  getNotificationDataFromUrl(): NotificationData {
    let data;
    try {
      data = this.extractNotificationsData();
    } catch (e) {
      console.error('Error parsing the url data, ', e);
    }
    return data;
  }

  private extractNotificationsData(): NotificationData {
    // slice the # so we retrieve all the encoded notifications data following the hash
    const hashFragment = location.hash.slice(1);
    // decode the URI to escape special characters
    const decodedUri = decodeURIComponent(hashFragment);
    // the URI is first compressed, then base64 encoded on the server, so we unwrap those steps here
    const compressedData = this.decodeBase64Data(decodedUri);
    const notificationsDataJson = pako.inflateRaw(compressedData, { to: 'string' });
    return JSON.parse(notificationsDataJson);
  }

  private decodeBase64Data(decodedUri: string): Uint8Array {
    // the pako library works best with arrays, so we decode the base64 string into one here
    const base64Decoded = atob(decodedUri);
    const rawLength = base64Decoded.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      array[i] = base64Decoded.charCodeAt(i);
    }
    return array;
  }
}
